import { HubConnection } from "@microsoft/signalr"
import { createContext, useContext } from "react"
import { Message as ChatMessage } from "../debugMessages"

export const SignalRContext = createContext(
    {
        connection: undefined, 
        messages: [],
        onUpdate: new EventTarget(),
        isConnected: false,
        connectionDead: false
    } as SignalRContextType
)


export default function useSignalR() {
    return useContext(SignalRContext)
}

export type SignalRContextType = {
    connection?: HubConnection
    messages: ChatMessage[]
    onUpdate: EventTarget
    isConnected: boolean
    connectionDead: boolean // True if the connection is dead and unrecoverable (i.e. license issue, no internet connection, etc.)
}
