import styles from './RobotPfp.module.css'

import Robot from '../../assets/images/robot.svg'

export default function RobotPfp() {
    return (
        <div className={styles.circleContainer}>
            <img src={Robot} alt='robot profile' />
        </div>
    )
}
