import styles from './chatView.module.css'

import Cross from '../../assets/images/cross.svg'
import Elipsis from '../../assets/images/elipsis.svg'

import ChatInput from '../input/text/chatInput'
import ChatBubble from '../bubbles/chatBubble'

import { useState } from 'react'
import { Message } from '../../debugMessages'
import RobotPfp from '../images/RobotPfp'
import { Tooltip } from 'react-tooltip'
import { AnimatePresence, motion } from 'framer-motion'


export default function ChatView(props: {sendMessage: (content: string) => void, messages: Message[], close: () => void, show: boolean}) {
    const [inputValue, setInputValue] = useState("")

    const handleSendMessage = () => {
        if (inputValue.trim() === "")
            return

        props.sendMessage(inputValue)
        setInputValue("")
    }

    return (
        <AnimatePresence presenceAffectsLayout={false}>
            {props.show && <motion.div 
                        className={styles.container}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>
                <div className={styles.header}>
                    <button /*onClick={props.close}*/>
                        <img alt='More Options' src={Elipsis} style={{filter: 'invert()'}}/>
                    </button>

                    <div className={styles.subHeader}>
                        <h4>IntraDesign Assistant</h4>
                        <Tooltip id='beta-tag-tooltip'/>
                        <p className={styles.betaTag} data-tooltip-id="beta-tag-tooltip" data-tooltip-html='This product is a work in progress and<br/>will change before final release'>Beta</p>
                    </div>

                    <button onClick={props.close}>
                        <img alt='Close' src={Cross} style={{filter: 'invert()'}}/>
                    </button>
                </div>

                <div className={styles.topInfo}>
                    <RobotPfp/>
                    <p>Chatting with AI Assistant</p>
                </div>

                <div className={styles.contentContainer}>
                    <div className={styles.contentWindow}>
                        <AnimatePresence>
                            {
                                [...props.messages].reverse().map(x => <ChatBubble message={x.content} key={x.id} fromAdmin={x.isAdmin} />)
                            }
                        </AnimatePresence>
                    </div>
                </div>

                <div className={styles.inputContainer}>
                    <ChatInput
                        value={inputValue} 
                        setContent={setInputValue} 
                        onSubmit={handleSendMessage}/>
                </div>
            </motion.div>}
        </AnimatePresence>
    )
}
