import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SignalRContext, SignalRContextType } from './hooks/useSignalRContext';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Message } from './debugMessages';


const root = ReactDOM.createRoot(
  document.getElementById('ai-chat-bot') as HTMLElement
);


const connectionBuilder = new HubConnectionBuilder()
  .withUrl(((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://localhost:5056/chat-hub" : "https://dev-api.coryirwin.com/chat-hub") + "?traceId=" + sessionStorage.getItem("aiTraceId"))
  .withAutomaticReconnect()
  .configureLogging(LogLevel.Information)
  .build();


const context: SignalRContextType = {
  connection: connectionBuilder,
  messages: [] as Message[],
  onUpdate: new EventTarget(),
  isConnected: false,
  connectionDead: false
}

async function startListening() {
  const localMessages = sessionStorage.getItem("aiMessages")
  if (localMessages && sessionStorage.getItem("aiTraceId"))
    context.messages = JSON.parse(localMessages) as Message[]

  try {
    connectionBuilder.off("ReceiveMessage")

    connectionBuilder.on("ReceiveMessage", (message: string, isAdmin: boolean, messageId: string) => {
      context.messages = [...context.messages, {content: message, isAdmin, id: messageId}]
      sessionStorage.setItem("aiMessages", JSON.stringify(context.messages))
      context.onUpdate.dispatchEvent(new Event('update'))
    })
    connectionBuilder.on("ReceiveTraceId", (traceId: string) => {
      sessionStorage.setItem("aiTraceId", traceId)
      sessionStorage.setItem("aiMessages", JSON.stringify([]))
    })
    connectionBuilder.onclose(() => {
      context.connectionDead = false
      context.onUpdate.dispatchEvent(new Event('update'))
    })
    connectionBuilder.onreconnecting(() => {
      context.isConnected = false
      context.onUpdate.dispatchEvent(new Event('update'))
    })
    connectionBuilder.onreconnected(() => {
      context.isConnected = true
      context.onUpdate.dispatchEvent(new Event('update'))
    })
    
    await connectionBuilder.start()
  
    await connectionBuilder.invoke("FinishedConnection")
    context.isConnected = true
    context.onUpdate.dispatchEvent(new Event('update'))
  }
  catch {
    context.connectionDead = true
    context.onUpdate.dispatchEvent(new Event('update'))
  }
  
  context.onUpdate.dispatchEvent(new Event('update'))
}
startListening()


root.render(
  <React.StrictMode>
    <SignalRContext.Provider value={context}>
      <App />
    </SignalRContext.Provider>
  </React.StrictMode>
)
