import styles from './chatBubble.module.css'

import { motion } from 'framer-motion'

export default function ChatBubble(props: ChatBubbleProps) {
    return (
        <motion.div className={styles.parentContainer} 
                    initial={{ opacity: 0, transform: 'TranslateY(50px)' }}
                    animate={{ opacity: 1, transform: 'TranslateY(0px)' }}
                    exit={{ opacity: 0, transform: 'TranslateY(50px)' }}>
            <div className={`${styles.container} ${(props.fromAdmin ? styles.primary : styles.secondary)}`}>
                <p>{props.message}</p>
            </div>
        </motion.div>
    )
}

type ChatBubbleProps = {
    message: string
    fromAdmin?: boolean
}
