import styles from './chatInput.module.css'

import PaperPlane from '../../../assets/images/paper-plane.svg'
import UpArrow from '../../../assets/images/up-arrow.svg'
import Caret from '../../../assets/images/caret-up.svg'


export default function ChatInput(props: {setContent: (newValue: string) => void, value: string, onSubmit: () => void}) {
    return (
        <div className={styles.container}>
            <textarea
                value={props.value} 
                onChange={x => props.setContent(x.target.value)} 
                className={styles.input} 
                placeholder='Type a message...'
                onKeyDown={e => {
                    if (e.key !== 'Enter')
                        return

                    props.onSubmit()
                    e.preventDefault()
                }}/>

            <button onClick={props.onSubmit}>
                <img src={Caret} alt='send button'/>
            </button>
        </div>
    )
}
