
import styles from './homeStyles.module.css'

import BubbleImage from './assets/images/message.svg'
import BrokenLink from './assets/images/broken-link.svg'
import NotificationSound from './assets/audio/notifications.mp3'

import ChatView from './components/views/chatView';
import AdminBubble from './components/bubbles/adminBubble';
import useSignalR from './hooks/useSignalRContext';
import { SyncLoader } from 'react-spinners';
import { useEffect, useReducer, useState } from 'react';
import { AnimatePresence } from 'framer-motion';


function App() {
    const [chatOpen, setChatOpen] = useState(false)
    //const [audio] = useState(new Audio(NotificationSound))
    const [lastOpenedIndex, setLastOpenedIndex] = useState(-1) // The last message that has been viewed in the chat window, by index.
    
    const signalR = useSignalR()

    // Beyond cursed but this updates the react component when the array changes. Requried since SignalR doesn't play
    // super nicely with react and we need to be able to receive messages pre-load
    const [,forceUpdate] = useReducer(msgCount => msgCount + 1, 0)
    useEffect(() => {
        signalR.onUpdate.addEventListener('update', forceUpdate)
        return () => signalR.onUpdate.removeEventListener('update', forceUpdate)
    }, [])

    useEffect(() => {
        if (signalR.messages.length <= 0)
            return

        // If the last message is from admin (server), play the audio clip
        if (signalR.messages[signalR.messages.length - 1].isAdmin) {
            try {
                //audio.volume = 0.5;
                //audio.play().catch(() => {})
            }
            catch (e) {
                console.error('Failed to play audio, see error: ', e)
            }
        }
    }, [signalR.messages.length])

    useEffect(() => {
        if (signalR.connectionDead || !signalR.isConnected)
            setChatOpen(false)
    }, [signalR.connectionDead, signalR.isConnected])


    const sendMessage = (content: string) => {
        if (!signalR.connection)
            return

        signalR.connection.invoke('SendMessage', content)
    }


    const adminMessages = signalR.messages.filter(x => x.isAdmin)
    let lastMessage = adminMessages.length > 0 ? adminMessages[adminMessages.length - 1] : null
    if (lastOpenedIndex >= adminMessages.length - 1)
        lastMessage = null


    const openChatWindow = () => {setLastOpenedIndex(adminMessages.length - 1); setChatOpen(x => !x)}


    return (
        <div className={styles.page}>
            <AnimatePresence>
                <div className={styles.container}>
                    <ChatView sendMessage={sendMessage} messages={signalR.messages} close={() => {setChatOpen(false)}} show={chatOpen}/>

                    { !chatOpen && lastMessage !== null && <AdminBubble fromAdmin message={lastMessage.content} /> }

                    <button onClick={signalR.isConnected ? openChatWindow : () => {}} className={`${styles.chatButton} ${(signalR.connectionDead ? styles.grey : styles.primaryColor)}`}>
                        { 
                            signalR.connectionDead ? <img alt='disconnected' src={BrokenLink}/> : 
                            ( signalR.isConnected ? <img alt='open chat window' src={BubbleImage}/> : <SyncLoader size={8} speedMultiplier={0.5} color='white'/> )
                        }
                    </button>
                </div>
            </AnimatePresence>
        </div>
    )
}

export default App
