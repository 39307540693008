import styles from './adminBubble.module.css'

export default function AdminBubble(props: ChatBubbleProps) {
    return (
        <div className={`${styles.container} ${(props.fromAdmin ? styles.primary : styles.secondary)}`}>
            <p>{props.message.length > 60 ? props.message.slice(0, 60).trimEnd() + '...' : props.message}</p>
        </div>
    )
}

type ChatBubbleProps = {
    message: string
    fromAdmin?: boolean
}
